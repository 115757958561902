import { Stack } from "@mui/material";

import { LoadingSpinner } from "../../components/LoadingSpinner";
import { LoadingTextAnimation } from "../../components/LoadingTextAnimation";

export function WorkplacesListLoadingState() {
  return (
    <Stack
      spacing={8}
      sx={{
        py: 10,
        px: 8,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingSpinner size="large" variant="primary" />

      <LoadingTextAnimation
        semibold
        variant="h5"
        interval={4000}
        texts={[
          "Finding workplaces nearby",
          "Analyzing workplace schedules",
          "Workplaces are on the way",
          "Digging deep into facility schedules",
          "Searching high and low for the best workplaces nearby",
          "Unlocking your next great opportunity",
        ]}
      />
    </Stack>
  );
}
