import { isDefined } from "@clipboard-health/util-ts";
import { matchPath, useLocation } from "react-router-dom";

import {
  SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH,
} from "./paths";
import { type ShiftModalRoutePathParams } from "./types";
import { useShiftModalsDataContext } from "./useShiftModalsDataContext";

export function useMatchShiftModalRoutes() {
  const { baseNavigationPath: defaultBaseNavigationPath, baseNavigationPathOverride } =
    useShiftModalsDataContext();

  const isNestedModalContext = isDefined(baseNavigationPathOverride);
  const baseNavigationPath = isNestedModalContext
    ? `${defaultBaseNavigationPath}/${baseNavigationPathOverride}`
    : defaultBaseNavigationPath;

  const { pathname } = useLocation();
  return matchPath<ShiftModalRoutePathParams>(pathname, {
    path: [
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH}`,
    ],
    exact: true,
    strict: false,
  });
}
