import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";

import { Button } from "../../components/Button";
import { Illustration } from "../../components/Illustration";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";

interface WorkplacesListEmptyStateProps {
  distanceFilter: number;
}

export function WorkplacesListEmptyState(props: WorkplacesListEmptyStateProps) {
  const { distanceFilter = DEFAULT_DISTANCE_IN_MILES_FILTER } = props;

  return (
    <Stack sx={{ flex: 1 }} alignItems="center" justifyContent="center" spacing={7}>
      <Stack alignItems="center" justifyContent="center">
        <Illustration type="location" />

        <Title
          semibold
          variant="h5"
          component="h3"
          sx={{ textWrap: "balance", textAlign: "center", width: "25ch" }}
        >
          There are no workplaces currently within {distanceFilter} miles of your center location
        </Title>
      </Stack>

      <Button href={DeprecatedGlobalAppV1Paths.WORKPLACE_REFERRALS} sx={{ px: 11 }}>
        Refer a workplace
      </Button>
    </Stack>
  );
}
